<template>
  <Detail></Detail>
</template>

<script>
import Detail from './components/exam/detail'
export default {
  name: "exam-detail2",
  components:{
    Detail
  },
}
</script>

<style scoped lang="scss">
</style>

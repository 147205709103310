<template>
  <div class="exam-result">
    <div class="top">
      <div>
      </div>
      <div class="top-left">
        <Input v-model="keyword"
               :placeholder="$t('project_search_project_nameid')"
               clearable
               search
               :enter-button="true"
               @on-enter="changeKeyword"
               @on-search="changeKeyword"
        />
      </div>
    </div>

    <Table border :columns="columns" :loading="loading" :data="data"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';

export default {
  name: "role",
  data(){
    return{
      keyword:'',
      columns: [
        {
          title: 'id',
          key:'id'
        },
        {
          title: this.$t('trainingcamp_exam_create_result_name'),
          key: 'name',
          render: (h, para) => {
            return h('span', para.row.user.nickname );
          }
        },
        // {
        //   title: this.$t('trainingcamp_exam_create_result_sex'),
        //   key: 'name',
        //   render: (h, para) => {
        //     return h('span', this.genders[para.row.user.gender]);
        //   }
        // },
        {
          title:this.$t('trainingcamp_exam_create_result_status'),
          key: 'name',
          render: (h, para) => {
            return h('span', this.statuses[para.row.status]);
          }
        },
        {
          title: this.$t('trainingcamp_exam_create_result_total_score'),
          key: 'score',
        },
        {
          title: this.$t('trainingcamp_exam_create_result_use_time'),
          key: 'use_time',
        },
        // {
        //   title: '切屏次数',
        //   key: 'name',
        // },
      ],
      data: [],
      loading:false,
      page:1,
      pageSize:10,
      total:0,
      info:{},
      genders:{},
      statuses:{},
    }
  },
  props:{
    examInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  watch:{
    examInfo:{
      handler(newV,oldV){
        this.info = newV;
        this.getList();
      },
      immediate:true,
    }
  },
  mounted(){

  },
  methods:{
    changeKeyword(data){
      this.keyword = data;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        train_id: this.info.id
      };
      this.api.dataset.trainUserList(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);
        this.genders = res.genders;
        this.statuses = res.statuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.exam-result{
  .top{
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left{
      width: 200px;
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>

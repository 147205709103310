<template>
<div>
  <div style="white-space: pre-line;line-height: 40px;font-size: 14px">
    {{info.attr.content}}
  </div>
<!--  <RichTxt :dataObj="{val:info.attr.content}" :isDisabled="true"></RichTxt>-->
</div>
</template>

<script>
import RichTxt from '@/components/production/richtxt.vue';
export default {
  name: "examExplain.vue",
  data(){
    return {

    }
  },
  components:{
    RichTxt
  },
  props:{
    examInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  watch:{
    examInfo:{
      handler(newV,oldV){
        this.info = newV;
      },
      immediate:true,
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="exam-detail">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="layout-content" >
      <div v-if="!loading">
        <Row type="flex" align="middle">
          <i-col span="2">
            <div class="head-img">
              <p>{{trainDeatil.name && trainDeatil.name[0].toUpperCase()}}</p>
            </div>
          </i-col>
          <i-col span="20" >
            <Row>
              <i-col span="24" class="project-detail-info">
                <div class="info-item" >
                  <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{ trainDeatil.name }}</strong>
                </div>
              </i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="8">{{ $t('trainingcamp_exam_detail_time') }} :   {{trainDeatil.start_time}} {{ $t('trainingcamp_exam_detail_to') }} {{trainDeatil.end_time}}</i-col>
              <i-col span="8">{{ $t('trainingcamp_exam_detail_total_score') }} :   {{trainDeatil.total_score}}</i-col>
              <i-col span="8">{{ $t('trainingcamp_exam_detail_time_long') }} :  {{trainDeatil.timelong}}</i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="8">{{ $t('trainingcamp_exam_detail_data_count') }} :   {{trainDeatil.data_count}}</i-col>
              <i-col span="8">{{ $t('trainingcamp_exam_detail_address') }} : {{ $t('trainingcamp_exam_detail_address_online') }}</i-col>
              <i-col span="8">{{ $t('trainingcamp_exam_detail_stu_num') }} :   {{trainDeatil.join_user_count}}</i-col>
            </Row>
          </i-col>
          <i-col span="2">
            <Button type="primary" v-if="trainDeatil.status == 2 && trainDeatil.my_joined_count == 0" @click="startExam">{{this.$t('trainingcamp_myexam_start_exam')}}</Button>
            <Button type="primary" v-if="trainDeatil.status == 2 && trainDeatil.my_joined_count > 0" :disabled="true">{{this.$t('trainingcamp_myexam_joined')}}</Button>
            <Button type="primary" v-if="trainDeatil.status != 2" :disabled="true">考试关闭</Button>
          </i-col>
        </Row>
      </div>
      <Spin fix v-else></Spin>
    </div>
    <div class="cont">
      <div class="cont-top">
        <div class="tab">
          <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="cont-item">
        <component v-if="trainDeatil" :is="curTab.componentName" :examInfo="trainDeatil"></component>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import ExamExplain from './examExplain'
import ExamList from './examList'
import ExamResult from './examResult'
import ExamStatistics from './examStatistics'

export default {
  name: "examDetail1",
  data(){
    return{
      trainDeatil:'',
      trainId:'',
      loading:false,
      typesObj:{},
      tabList:[],
      curTab:{
        name:this.$t('trainingcamp_exam_detail_explain'),
        id:1,
        componentName:'ExamExplain',
        tabName:'explain'
      },
      trainUser:{},
    }
  },
  components:{
    ExamExplain,
    ExamList,
    ExamResult,
    ExamStatistics
  },
  created(){


    this.trainId = this.$route.query.id;
    console.log(this.trainId,this.$route.query.id,'this.$route.query')
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        id:this.trainId
      };
      this.loading = true;
      this.api.dataset.trainDataDetail(params).then((res)=>{
        this.loading = false;
        this.trainDeatil = res.info;

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.trainDeatil.start_time = util.timeFormatter(
            new Date(+res.info.start_time * 1000),
            'yyyy-MM-dd hh:mm'
        );
        this.trainDeatil.end_time = util.timeFormatter(
            new Date(+res.info.end_time * 1000),
            'yyyy-MM-dd hh:mm'
        );
        this.trainUser = res.trainUser;
        if(this.trainDeatil.status == 2 && this.trainDeatil.my_joined_count == 0){
          if(this.trainDeatil.generate_data_rule == 3){
            this.tabList = [
              {
                name:this.$t('trainingcamp_exam_detail_result'),
                id:3,
                componentName:'ExamResult',
                tabName:'result'
              },
            ];
          }else{
            this.tabList = [
              {
                name:this.$t('trainingcamp_exam_detail_explain'),
                id:1,
                componentName:'ExamExplain',
                tabName:'explain'
              },
              {
                name:this.$t('trainingcamp_exam_detail_result'),
                id:3,
                componentName:'ExamResult',
                tabName:'result'
              },
            ];
          }

        }else{
          if(this.trainDeatil.generate_data_rule == 3){
            this.tabList = [
              // {
              //   name:this.$t('trainingcamp_exam_detail_explain'),
              //   id:1,
              //   componentName:'ExamExplain',
              //   tabName:'explain'
              // },
              {
                name:this.$t('trainingcamp_exam_detail_list'),
                id:2,
                componentName:'ExamList',
                tabName:'list'
              },
              {
                name:this.$t('trainingcamp_exam_detail_result'),
                id:3,
                componentName:'ExamResult',
                tabName:'result'
              },
              // {
              //   name:this.$t('trainingcamp_exam_detail_statistics'),
              //   id:4,
              //   componentName:'ExamStatistics',
              //   tabName:'statistics'
              // }
            ];
          }else{
            this.tabList = [
              {
                name:this.$t('trainingcamp_exam_detail_explain'),
                id:1,
                componentName:'ExamExplain',
                tabName:'explain'
              },
              {
                name:this.$t('trainingcamp_exam_detail_list'),
                id:2,
                componentName:'ExamList',
                tabName:'list'
              },
              {
                name:this.$t('trainingcamp_exam_detail_result'),
                id:3,
                componentName:'ExamResult',
                tabName:'result'
              },
              // {
              //   name:this.$t('trainingcamp_exam_detail_statistics'),
              //   id:4,
              //   componentName:'ExamStatistics',
              //   tabName:'statistics'
              // }
            ];
          }

        }
        this.curTab = this.tabList.filter((item)=>{
          return item.tabName == this.$route.query.tab || 'explain';
        })[0];

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        path: this.$route.path,
        query: {
          id: this.$route.query.id,
          tab: data.tabName,
        }
      });
    },
    startExam(){
      if(this.trainDeatil.my_join_status == 1){
        this.$router.push({
          path:'/exam',
          query:{
            id:this.trainDeatil.id,
          }
        })
      }else{
        this.$router.push({
          path:'/trainingcamp/pre-exam',
          query:{
            id:this.trainDeatil.id,
          }
        })
      }

    },
  }
}
</script>

<style scoped lang="scss">
.exam-detail{
  margin: 20px;
  .layout-content{

    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin-top: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
    .cont-item{
      margin-top: 20px;
    }
  }

}
</style>

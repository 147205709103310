<template>
  <div class="exam-list">
    <div class="exam-list-top">
      <div>
        <span>{{$t('trainingcamp_exam_create_list_type')}}:</span>
        <Select v-model="type" style="width:200px" @on-change="changeTop">
          <Option v-for="item in typesList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div>
        <span>{{ $t('trainingcamp_exam_create_list_level') }}:</span>
        <Select v-model="level" style="width:200px" @on-change="changeTop">
          <Option v-for="item in levelsList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
    </div>
    <div class="table">
      <Table :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change = "changePageSize" :current="page" show-total show-elevator show-sizer placement = "top" transfer />
    </div>
  </div>
</template>

<script>
export default {
  name: "examList",
  data(){
    return{
      type:'',
      level:'',
      loading:false,
      columns:[
        {
          title: this.$t('trainingcamp_exam_create_list_index'),
          key:'id',
          width: 120,
        },
        {
          title: this.$t('trainingcamp_exam_create_list_data'),
          key:'name',
        },
        {
          title: this.$t('trainingcamp_exam_create_list_type'),
          width: 120,
          render: (h, params) => {
            return h('span',this.typesObj[params.row.type])
          }

        },
        {
          title: this.$t('trainingcamp_exam_create_list_level'),
          width: 120,
          render: (h, params) => {
            return h('span',this.levelsObj[params.row.level])
          }
        },
      ],
      dataList:[],
      total:0,
      page:1,
      pageSize:10,
      info:{},
      levelsObj:{},
      levelsList:[],
      typesObj:{},
      typesList:[],
    }
  },
  props:{
    examInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  watch:{
    examInfo:{
      handler(newV,oldV){
        this.info = newV;
        this.getDataList();
      },
      immediate:true,
    }
  },
  methods:{
    getDataList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        // category_id:this.info.generate_id,
        level:this.level,
        type:this.type,
        train_id:this.info.id
      };
      this.loading = true;
      this.api.dataset.trainDataList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count);

        this.levelsObj = res.data_levels;
        this.levelsList = this.formatData(this.levelsObj);
        this.typesObj = res.data_types;
        this.typesList = this.formatData(this.typesObj);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    formatData(data){
      let arr = [];
      for(let name in data){
        arr.push({
          label:data[name],
          value:name
        });
      }
      return arr;
    },
    changeTop(){
      this.page = 1;
      this.getDataList();
    },
    changePage(page){
      this.page = page;
      this.getDataList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getDataList();
    },
  }
}
</script>

<style scoped lang="scss">
.exam-list{
  .exam-list-top{
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        padding-right: 5px;
      }
    }
  }
  .select-num{
    margin-top: 20px;
    >span{
      padding: 0 5px;
      color:#2d8cf0;
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}
</style>
